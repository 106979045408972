// 手機板header menu開關
/** lang */
(() => {
  const lang = document.querySelector('.header-lang');
  const langText = document.querySelector('.header-lang-text');
  const langOptions = document.querySelector('.header-lang-options');

  // 點擊顯示選項
  langText.addEventListener('click', () => {
    langOptions.classList.toggle('active');
  });

  // 點擊其他地方關閉
  document.addEventListener('click', (e) => {
    if (!lang.contains(e.target)) {
      langOptions.classList.remove('active');
    }
  });
})();

/** menu */
(() => {
  const menu = document.querySelector('.header-menu');
  const menuOpen = document.querySelector('.header-menu-open');
  const menuClose = document.querySelector('.header-menu-close');
  const nav = document.querySelector('.header-nav');

  // 點擊顯示選項
  menu.addEventListener('click', () => {
    menuOpen.classList.toggle('active');
    menuClose.classList.toggle('active');
    nav.classList.toggle('active');
  });
})();

// kv swiper
(() => {
  const ele = document.querySelector('.swiper-box .mySwiper');
  if (!ele) return;

  const swiper = new Swiper(ele, {
    slidesPerView: 2,
    spaceBetween: 20,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },

    pagination: {
      el: '.swiper-box .swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-box .swiper-button-next',
      prevEl: '.swiper-box .swiper-button-prev',
    },
    grid: {
      fill: 'row',
      rows: 2,
    },
    breakpoints: {
      1024: {
        slidesPerView: 6,
        spaceBetween: 20,
      },

      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });
})();
